<template>
    <header class="header-global">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="img/logos/orion-travea.png">
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <a href="../index.html">
                        <img src="img/logos/orion-travea.png">
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                <a href="/" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="fa fa-home d-lg-none"></i>
                        <span class="nav-link-inner--text">Home</span>
                </a>

                <li class="nav-item dropdown">                    
                    <a href="features" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-ui-04 d-lg-none"></i>
                        <span class="nav-link-inner--text">Features</span>
                    </a>
                 
                    <ul class="dropdown-menu multi-column dropdown-menu-xxl">
                        <div class="row">
                            <div class="col-sm-6">
                                <ul class="multi-column-dropdown">
                                    <a href="invoicing" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                        <i class="fa fa-sticky-note"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">Invoicing</h6>                                 
                                    </div>
                                    </a>
                                    <a href="tax" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                        <i class="fa fa-gavel"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">Tax</h6>                                    
                                    </div>
                                    </a>
                                    <a href="reconcilliation" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                        <i class="fa fa-wrench"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">Reconciliations</h6>                                 
                                    </div>
                                    </a>
                                    <a href="multiCurrency" class="media d-flex align-items-center">
                                        <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                            <i class="fa fa-usd"></i>
                                        </div>
                                        <div class="media-body ml-3">
                                            <h6 class="text-dark mb-md-1">Multi Currency</h6>                                    
                                        </div>
                                    </a>
                                    <a href="inter_branch" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                        <i class="fa fa-arrows-h"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">Inter Branch Accounting</h6>                                 
                                    </div>
                                    </a>
                                    <a href="reports" class="media d-flex align-items-center">
                                        <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                            <i class="fa fa-table"></i>
                                        </div>
                                        <div class="media-body ml-3">
                                            <h6 class="text-dark mb-md-1">Reports</h6>                                    
                                        </div>
                                    </a>
                                </ul>
                            </div>

                            <div class="col-sm-6">
                                <ul class="multi-column-dropdown">
                                    <a href="deals" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                        <i class="fa fa-flash"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">PLB Special Deals</h6>                                 
                                    </div>
                                    </a>
                                    <a href="technology" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                        <i class="fa fa-code"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">Technology</h6>                                    
                                    </div>
                                    </a>
                                    <a href="" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                        <i class="fa fa-file-archive-o"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1"> File Generation</h6>                                 
                                    </div>
                                    </a>
                                    <a href="" class="media d-flex align-items-center">
                                        <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                            <i class="fa fa-flag"></i>
                                        </div>
                                        <div class="media-body ml-3">
                                            <h6 class="text-dark mb-md-1">Add On Features</h6>                                    
                                        </div>
                                    </a>
                                    <a href="" class="media d-flex align-items-center">
                                    <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                        <i class="fa fa-suitcase"></i>
                                    </div>
                                    <div class="media-body ml-3">
                                        <h6 class="text-dark mb-md-1">Inventory</h6>                                 
                                    </div>
                                    </a>
                                    <a href="" class="media d-flex align-items-center">
                                        <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                            <i class="fa fa-cogs"></i>
                                        </div>
                                        <div class="media-body ml-3">
                                            <h6 class="text-dark mb-md-1">Automation</h6>                                    
                                        </div>
                                    </a>
                                </ul>
                                
                            </div>
                            
                        </div>
                        <div style="background-color:#fcc3a7" class="col-sm-12 text-center">
                            <a href="Features" class="media d-flex align-items-center">                              
                                <div class="media-body">
                                    <h6 class="text-default mb-md-1"><i class="fa fa-th"></i>&nbsp;View All features</h6>                                    
                                </div>
                            </a>
                         </div>
                    </ul>
                </li>

                <li class="nav-item">
                    <a href="pricing" class="nav-link" role="button">
                        <i class="ni ni-money-coins d-lg-none"></i>
                        <span class="nav-link-inner--text">Pricing</span>
                    </a>                 
                </li>

                 <li class="nav-item">
                    <a href="contact" class="nav-link" data-toggle="" role="button">
                        <i class="ni ni-tablet-button d-lg-none"></i>
                        <span class="nav-link-inner--text">Contact Us</span>
                    </a>
                 
                </li>
                 <li class="nav-item">
                    <a href="login_redirect" class="nav-link" data-toggle="" role="button">
                        <i class="ni ni-curved-next d-lg-none"></i>
                        <span class="nav-link-inner--text">Login</span>
                    </a>
                    
                </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
               
                <li class="nav-item d-none d-lg-block ml-lg-4">
                    <a href="register"
                       class="btn btn-neutral btn-icon">
                <span class="btn-inner--icon">
                  <i class="ni ni-tag mr-2"></i>
                </span>
                        <span class="nav-link-inner--text">Try it Free</span>
                    </a>
                </li>
            </ul>
        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
// import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
export default {
  components: {
    BaseNav,
    CloseButton,
    // BaseDropdown
  }
};
</script>
<style>
</style>
