<template>
    <footer class="footer has-cards ">        
        <div class="container">
            <div class="row row-grid">
                <div class="col-footer">
                    <img src="img/logos/orion-travea.png">
                </div>
                 <div class="col-footer">
                        <div class="heading">
                            Company
                        </div>
                        <ul class="list-unstyled">
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Our Story</a></li>
                            <li><a href="contact">Contact</a></li>
                        </ul>
                </div>
                 <div class="col-footer">
                        <div class="heading">
                            Product
                        </div>
                        <ul class="list-unstyled">
                            <li><a href="#">Invoice Software</a></li>
                            <li><a href="#">Financial Reports</a></li>
                            <li><a href="#">Pricing</a></li>
                        </ul>
                </div>   
                <div class="col-footer">
                        <div class="heading">
                            Helpful Links
                        </div>
                        <ul class="list-unstyled">
                            <li><a href="login_redirect">Login</a></li>
                            <li><a href="contact">Support</a></li>
                            <li><a href="#">Sitemap</a></li>
                        </ul>
                </div>
                <div class="col-footer">
                        <div class="heading">
                            Policies
                        </div>
                        <ul class="list-unstyled">
                            <li><a href="terms">Terms of Service</a></li>
                            <li><a href="privacy">Privacy Policy</a></li>

                        </ul>
                         <div class="heading">
                            Resources
                        </div>
                        <ul class="list-unstyled">
                            <li><a href="#">Documentation</a></li>

                        </ul>
                </div>  
                                      
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                
                    <div class="copyright col-md-8">
                        &copy; {{year}}
                    </div>
                    <div class="col-md-2">
                        <a target="_blank" href="https://twitter.com/"
                        class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg" data-toggle="tooltip"
                        data-original-title="Follow us">
                            <i class="fa fa-twitter"></i>
                        </a>
                        <a target="_blank" href="https://www.facebook.com/"
                        class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                        data-original-title="Like us">
                            <i class="fa fa-facebook-square"></i>
                        </a>                      
                    </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'starter-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
