<template>
  <div>
    <div class="container shape-container d-flex">
      <div class="col px-0 my-5">
        <div class="row text-center">
          <div class="col-lg-12">
            <h1 class="display-4 text-dark">
              <span>Sales & Invoicing</span>
            </h1>
            <div class="desc_head">
              Sales and Invoicing is one of the main pillers of travel
              accounting.Travea enhance your business by perfectly managing all
              sales and transactions.
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-3 ml-lg-auto feat_cap my-auto">
            
              Enhance <br />
              your <br />
              business
           
          </div>
          <div class="col-md-9 order-lg-2 ml-lg-auto" >
            <div class="position-relative pl-md-5">
              <img
                src="img/screenshots/feat_inv.png"
                class="img-center img-fluid feat-img-home feat-img-anime"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;1,900&display=swap');
.feat_head_underline {
  left: 0;
  bottom: 1px;
  border-width: 0 0 0px;
  border-style: solid;
  border-bottom: 1px solid #080808;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px;
  
}

.desc_head {
  padding: 5px;
  font-size: 1.2rem;
  line-height: 3rem;
}
.feat-img-home {
  border-radius: 2px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
  transition: width 1s;
}

.feat_cap {
   line-height: 88px;
   font-size: 50px;
   font-family: 'Roboto', sans-serif;

}
.feat-img-anime {
  animation-duration: 1s;animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }


  to {
    margin-left: 0%;
    width: 100%;
  }
}

</style>
