<template>
  <div>
    <section class="section section-lg pt-lg-0">
      <div class="container">
        <card class="border-1 mt-3">
          <div class="container shape-container d-flex">
            <div class="col px-0 my-0">
              <div class="row">
                <div class="col-lg-6 ">
                  <h1 class="display-3  text-dark">
                    <span>End-to-End Solution</span>
                  </h1>
                  <h5 class="mt-4">
                    <icon name="fa fa-bars"></icon>
                    <span>BSP Hot Files</span>
                  </h5>
                  <div class="mx-auto">
                    <p class="mx-2">
                      Captures tickets and refund information from data
                      files that have been transmitted by the GDS/ticketing
                      system.
                    </p>
                  </div>
                  <h5 class="mt-4">
                    <icon name="fa fa-file-text"></icon>
                    <span class="text-capitalize">Invoicing</span>
                  </h5>
                  <div class="mx-auto">
                    <p class="mx-2">
                      Make sales for multiple services in a single invoice.
                      Multiple currency, Supplier sharing, Auto invoicing from
                      GDS and suppliers multiple FOP are some of the many
                      invoicing features of Travea
                    </p>
                  </div>
                  <h5 class="mt-4">
                    <icon name="fa fa-magic "></icon>
                    <span class="text-capitalize">Reconciliation</span>
                  </h5>
                  <div class="mx-auto">
                    <p class="mx-2">
                      BSP,Bank,Credit & Corporate Cards,Hotel & Other
                      Suppliers,Budget Airlines are the current features made available
                      for reconciliation
                    </p>
                  </div>
                  <h5 class="mt-4">
                    <icon name="ni ni-money-coins"></icon>
                    <span>Transactions</span>
                  </h5>
                  <div class="mx-auto">
                    <p class="mx-2">
                      Receipts, Payments, Journals, ADM, ACM, Debit &
                      Credit Notes Reco-Documents, Tax JVs and Cash Invoices
                    </p>
                  </div>
                  <h5 class="mt-4">
                    <icon name="fa fa-university"></icon>
                    <span>Tax Modules</span>
                  </h5>
                  <div class="mx-auto">
                    <p class="mx-2 my-1">
                      Input & Output- Categorized by country wise,
                      service wise,airport wise,branch wise
                    </p>
                  </div>
                  <h5 class="mt-4">
                    <icon name="fa fa-pie-chart "></icon>
                    <span>Reports</span>
                  </h5>
                  <div class="mx-auto">
                    <p class="mx-2">
                      Financial Reports, Sales Reports & Graphical Dashboards,
                      Productivity Reports, Audit Reports
                    </p>
                  </div>
                </div>
                <div class="col-md-6 order-lg-2 ml-lg-auto my-auto">
                  <div class="position-relative pl-md-5">
                    <img src="img/ill/ete.jpg" class="img-center img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <section class="section section-lg pt-lg-0">
      <div class="container">
        <card class="border-1">
          <div class="container shape-container d-flex">
            <div class="col px-0">
              <div class="row">
                <div class="col-md-6 mt-5 order-rg-2 ml-lg-auto">
                  <div class="position-relative">
                    <img src="img/ill/erp.jpg" class="img-center img-fluid" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <h1 class="display-3  text-dark">
                    <span> Travel Accounting ERP </span>
                  </h1>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon name="fa fa-plane"></icon>
                      <span>Flights</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon name="fa fa-building"></icon>
                      <span>Hotels</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon name="fa fa-id-card-o"></icon>
                      <span>Visa</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon name="fa fa-car"></icon>
                      <span>Tour / Holidays</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon name="fa fa-th-large "></icon>
                      <span>Other Services</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                   <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon name="fa fa-users "></icon>
                      <span>Front / Back Office</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <section class="section section-lg pt-lg-0">
      <div class="container">
        <card class="border-1">
          <div class="container shape-container d-flex">
            <div class="col px-0">
              <div class="row">
                <div class="col-lg-6">
                  <h1 class="display-3  text-dark">
                    <span>Simply Reach Us</span>
                  </h1>
                   <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon style="color:  #0e900a " name="fa fa-phone "></icon>
                      <span>Best Support Team</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon style="color:  #0e900a " name="fa fa-flash "></icon>
                      <span>Easy To Migrate</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon style="color:  #0e900a " name="fa fa-exclamation-circle "></icon>
                      <span>Customize Your Way</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <h5 class="mt-4">
                      <icon style="color:  #0e900a " name="fa fa-cubes "></icon>
                      <span>Integrated Platform</span>
                    </h5>
                    <div>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 order-lg-2 ml-lg-auto">
                  <div class="position-relative pl-md-5">
                    <img src="img/ill/sru.jpg" class="img-center img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    etelist: [],

    erplist: [
      { name: "Flight" },
      { name: "Hotel" },
      { name: "Visa sales" },
      { name: "Tour / Holidays" },
      { name: "Other Services" },
      { name: "User Controls" },
      { name: "Front Office" },
      { name: "Back Office" },
    ],

    reachlist: [
      { name: "Integrated Platform" },
      { name: "Portal integration" },
      { name: "GDS data capture" },
      { name: "Excel upload invoicing" },
      { name: "Debtors Management" },
      { name: "Easy to Migrate" },
      { name: "Customize on your way" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
p {
  text-align: justify;
}
</style>
