<template>
  <section>
    <div class="container">
     
        <div class="col-lg-12">    
            <template>             
              <form role="form">
                   <div class="my-3 row">
                  <div class="col-sm-2">
                <label for="" style="align-items: center;">Active Port</label>
                </div>
                <div class="col-sm-10">
                <base-input
                  alternative
                  class="admin-set-styles-input"
                  
                >
                </base-input>
                  </div>
                   </div>
                 <div class="my-3 row">
                  <div class="col-sm-2">
                <label for="" style="align-items: center;">Active/Enquiry Mode</label>
                </div>
                <div class="col-sm-10">
                <base-switch></base-switch>             
                  </div>
                   </div>

                <div style="float:right">
                  <base-button
                    tag="a"
                    href=""
                    type="primary"
                    class="my-4"
                    >save changes</base-button
                  >
                  <base-button
                    tag="a"
                    href=""
                    type="danger"
                    class="my-4"
                    >cancel</base-button
                  >
                </div>
              </form>
            </template>
         
        </div>
      </div>
  </section>
</template>
<script>
export default {};
</script>


<style lang="scss" scoped>

.admin-set-styles-input {
          border: 2px solid green;
}


</style>
