<template>
  <div>
    <div class="container shape-container d-flex">
      <div class="col px-0 my-5">
        <div class="row text-center">
          <div class="col-lg-12">
            <h1 class="display-3 text-dark">
              <span>Settlement</span>
            </h1>
            <div class="desc_head">
              End dispute over money.
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-3 ml-lg-auto feat_cap my-auto">
            
              Earn <br />
              your <br />
              revenue
           
          </div>
          <div class="col-md-9 order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img
                src="img/screenshots/feat_settle.png"
                class="img-center img-fluid feat-img-home feat-img-anime"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
