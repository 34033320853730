<template>
  <section
    class="section section-shaped section-lg my-0"
    style="min-height:100vh"
  >
    <div class="shape shape-style-1 bg-gradient-secondary"></div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <card
            type="white"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-1"
            style="min-height : 410px;border-bottom: 1mm ridge rgb(255, 100, 0, .6);"
          >
            <template>
              <div class="tralogo">
                <img src="img/logos/tra_logo.jpg" alt="" />
                <div class=" text-center mt-4">
                  Travea - Your one stop travel accounting ERP to cater all your
                  needs.Thank you for choosing us.
                </div>
              </div>
            </template>
          </card>
        </div>
        <div class="col-lg-8">
          <card
            type="white"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-1"
            style="min-height : 410px;border-bottom: 1mm ridge rgb(170, 50, 220, .6);"
          >
            <template>
              <div class=" my-4">
                <p style="color:blue">Welcome,</p>
                Let us know where you are , we will configure Travea exclusively
                for you!
              </div>
              <form role="form">
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-map-marker"></i
                    ></span>
                  </div>
                  <select class="form-control" v-model="intCountryId">
                    <option value="" disabled selected hidden>Country</option>
                    <option
                      v-for="each_country in lstCountries"
                      v-bind:key="each_country.name"
                      v-bind:value="each_country.name"
                    >
                      {{ each_country.name }}
                    </option>
                  </select>
                </div>

                <div
                  style="border-left: 20px solid red;background-color: #F5F5F5;border-radius: 50px 20px;"
                  class="row mt-3 mx-auto py-2"
                  v-if="arrErrors.length"
                >
                  <p class="text-danger mx-auto my-auto">
                    <b>Country Required</b>
                  </p>
                </div>

                <div
                  v-if="loading"
                  class="row mt-3 mx-auto py-2 px-auto"
                  style="text-align:center;display: flex;
  justify-content: center;"
                >
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                  ></pulse-loader>
                  <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                </div>
                <div v-else>
                  <!-- request finished -->
                </div>
                <div>
                  <base-button type="primary" class="my-4" @click="fnRedirect()"
                    >Get Started</base-button
                  >
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import lst_countries from "../assets/countries.js";
import lst_redirect from "../globals/redirects.js";
export default {
  data: () => ({    
    lstCountries: lst_countries,
    lstr: lst_redirect,
    intCountryId : "",
    arrErrors: [],
    loading: "",
    intBtnDisableFlag: "",
  }),
  methods: {
    fnRedirect() {
      this.arrErrors = [];
      if (!this.intCountryId) {
        this.arrErrors.push("Country required.");
      }
      if (this.arrErrors.length > 0) {
        this.intBtnDisableFlag = 1;
        this.loading = false;
        return false;
      } else {
          console.log(this.intCountryId)
          if(Object.prototype.hasOwnProperty.call(lst_redirect, this.intCountryId)) {
                window.location.href = lst_redirect[this.intCountryId]  
          }
          else{
              window.location.href = lst_redirect['Others']   
          }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tralogo {
  img {
    border-radius: 50%;
    height: 150px;
    border: 1px solid #ddd;
    padding: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  }
  img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .iconspan {
    float: left;
    margin-left: 6px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    color: red;
  }
}
</style>
