<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-primary shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-6">
                <h1 class="display-3  text-white">
                  <span>Are you a travel agent ?</span>
                </h1>
                <p class="lead text-white" style="text-align:justify">
                  Looking for an affordable accounting software ? You are at the
                  right place. We can help you out irrespective of the size of
                  your business. Travea have one stop travel accounting ERP to
                  cater all your needs.
                </p>
                <div class="btn-wrapper">
                  <!-- <base-button tag="a"
                                                 href="register"
                                                 class="mb-3 mb-sm-0"
                                                 type="warning text-white"                                                 
                                                 icon="ni ni-cart">
                                        Purchase
                                    </base-button> -->
                  <base-button
                    tag="a"
                    href="register"
                    class="mb-3 mb-sm-0"
                    type="warning text-white"
                    icon="fa fa-tag"
                  >
                    Free Trial
                  </base-button>
                </div>
              </div>
              <div class="col-md-6 order-lg-2 ml-lg-auto">
                <div class="position-relative pl-md-5">
                  <img src="img/ill/ill-2.svg" class="img-center img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card
                  class="border-0"
                  hover
                  shadow
                  body-classes="py-5"
                  style="min-height:550px"
                >
                  <icon
                    name="ni ni-money-coins"
                    type="primary"
                    rounded
                    class="mb-4"
                  >
                  </icon>
                  <h6 class="text-primary text-uppercase">
                    End-to-end solution
                  </h6>
                  <p class="description mt-3" style="text-align:justify">
                    We provide you service from beginning to end and deliver a
                    complete functional solution thereby making third party
                    products expendable.Travea eliminates as many middle layers
                    or steps as possible, which helps to optimize the
                    performance and efficiency of your business
                    <br>
                    <br>
                   
                  </p>
                  <div>
                    <badge type="primary" rounded>Accounts</badge>
                    <badge type="primary" rounded>Integrations</badge>
                    <badge type="primary" rounded>Hrm</badge>
                  </div>

                  <base-button
                    tag="a"
                    href="features"
                    type="primary"
                    class="mt-4"
                  >
                    Learn more
                  </base-button>
                </card>
              </div>
              <div class="col-lg-4">
                <card
                  class="border-0"
                  hover
                  shadow
                  body-classes="py-5"
                  style="min-height:550px"
                >
                  <icon
                    name="ni ni-collection"
                    type="success"
                    rounded
                    class="mb-4"
                  >
                  </icon>
                  <h6 class="text-success text-uppercase">
                    TRAVEL ACCOUNTING ERP
                  </h6>
                  <p class="description mt-3" style="text-align:justify">
                    Travea plans and manages all the core supply chain,
                    services, financial and other processes or an
                    organization.Enterprise Resource Planning software can be
                    used to automate and simplify individual activities across a
                    business or organization.
                    <br>
                    <br>
                    <br>
                  </p>
                  <div>
                    <badge type="success" rounded>Imports</badge>
                    <badge type="success" rounded>tax</badge>
                    <badge type="success" rounded>reports</badge>
                  </div>
                  <base-button
                    tag="a"
                    href="features"
                    type="success"
                    class="mt-4"
                  >
                    Learn more
                  </base-button>
                </card>
              </div>
              <div class="col-lg-4">
                <card
                  class="border-0"
                  hover
                  shadow
                  body-classes="py-5"
                  style="min-height:550px"
                >
                  <icon name="ni ni-planet" type="warning" rounded class="mb-4">
                  </icon>
                  <h6 class="text-warning text-uppercase">Simply reach us</h6>
                  <p class="description mt-3" style="text-align:justify">
                    We increase our capacity and functionalities based on users’
                    demands.Hence Travea can remain stable while adapting to
                    changes, upgrades, overhauls, and resource reduction.It is
                    assured that our well experienced technical support will
                    ensure your process moves hustle free by resolving technical
                    issues immediately
                  </p>
                  <div>
                    <badge type="warning" rounded>Support</badge>
                    <badge type="warning" rounded>Sales</badge>
                    <badge type="warning" rounded>Consultation</badge>
                  </div>
                  <base-button
                    tag="a"
                    href="features"
                    type="warning"
                    class="mt-4"
                  >
                    Learn more
                  </base-button>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <tabs fill class="flex-column flex-md-row">
      <card shadow>
        <tab-pane title="Receivables">
          <span slot="title" class="text-uppercase">
            Receivables
          </span>
          <div class="row">
            <div class="col-md-5">
              <div>
                <h2>
                  Recording Accounts Receivables
                </h2>
                <div class="row mx-auto my-2 mt-4">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Debtors Management</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Main Ledgers</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Multiple customer in same invoice/ Receipts/Payments/ JV
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Multiple form of Payments in Documents
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Duplicate/ copy customer Data for easy data entry
                  </p>
                </div>
                <!-- <div class="row mx-auto my-2">
                <icon
                  name="ni ni-key-25"
                  size="sm"
                  class="bg-gradient-white"
                  color="primary"
                  
                  rounded
                ></icon>
                  <p class="mx-2 my-1">VAT Enabled</p>
              </div> -->
              </div>
            </div>
            <div class="col-md-7 order-lg-2 ml-lg-auto pb-5">
              <div class="position-relative pl-md-5 ">
                <img
                  src="img/screenshots/Receipt.png"
                  class="img-center img-fluid feat-img-home"
                />
              </div>
            </div>
          </div>
        </tab-pane>

        <tab-pane title="Payables">
          <span slot="title" class="text-uppercase">
            Payables
          </span>
          <div class="row">
            <div class="col-md-5">
              <div>
                <h2>
                  Recording Accounts Payable
                </h2>
                <div class="row mx-auto my-2 mt-4">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Supplier sharing</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Merged reports for supplier act as customers
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Main Ledgers to identify balances of other accounts
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Supplier Reconciliation</p>
                </div>
                <!-- <div class="row mx-auto my-2">
                <icon
                  name="ni ni-key-25"
                  size="sm"
                  class="bg-gradient-white"
                  color="primary"
                  
                  rounded
                ></icon>
                  <p class="mx-2 my-1">Invoice Splitting</p>
              </div>
               <div class="row mx-auto my-2">
                <icon
                  name="ni ni-key-25"
                  size="sm"
                  class="bg-gradient-white"
                  color="primary"
                  
                  rounded
                ></icon>
                  <p class="mx-2 my-1">VAT Enabled</p>
              </div> -->
              </div>
            </div>
            <div class="col-md-7 order-lg-2 ml-lg-auto pb-5">
              <div class="position-relative pl-md-5 ">
                <img
                  src="img/screenshots/Payment.png"
                  class="img-center img-fluid feat-img-home"
                />
              </div>
            </div>
          </div>
        </tab-pane>

        <tab-pane title="Invoicing">
          <span slot="title" class="text-uppercase">
            Invoicing
          </span>
          <!-- <p class="description"> INVOICING </p> -->
          <div class="row">
            <div class="col-md-5">
              <div>
                <h2>
                  Faster & Simpler Invoicing for Travel Agencies and Manage
                  Sales
                </h2>
                <div class="row mx-auto my-2 mt-4">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Auto/Manual invoicing through API</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Data capture invoicing from GDS( Amadeus / Galileo/ Sabre)
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Multiple services in single invoice</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Multiple customers/ suppliers</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Cash invoice cum receipt.</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Instant matching/ allocation</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Replicate data options for easy data entry
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-7 order-lg-2 ml-lg-auto pb-5">
              <div class="position-relative pl-md-5 ">
                <img
                  src="img/screenshots/feat_inv.png"
                  class="img-center img-fluid feat-img-home"
                />
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane title="Tax">
          <span slot="title" class="text-uppercase">
            Tax
          </span>
          <div class="row">
            <div class="col-md-5">
              <div>
                <h2>
                  Unique Method to Manage Billing Tax
                </h2>
                <div class="row mx-auto my-2 mt-4">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Input & output ledgers</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Tax return format as per goverment format
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    Dynamic tax setting( service wise/ country wise)
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Inclusive exclusive tax settings</p>
                </div>
                <!-- <div class="row mx-auto my-2">
                <icon
                  name="ni ni-key-25"
                  size="sm"
                  class="bg-gradient-white"
                  color="primary"
                  
                  rounded
                ></icon>
                  <p class="mx-2 my-1">Invoice Splitting</p>
              </div>
               <div class="row mx-auto my-2">
                <icon
                  name="ni ni-key-25"
                  size="sm"
                  class="bg-gradient-white"
                  color="primary"
                  
                  rounded
                ></icon>
                  <p class="mx-2 my-1">VAT Enabled</p>
              </div> -->
              </div>
            </div>
            <div class="col-md-7 order-lg-2 ml-lg-auto pb-5">
              <div class="position-relative pl-md-5 ">
                <img
                  src="img/screenshots/Tax.png"
                  class="img-center img-fluid feat-img-home"
                />
              </div>
            </div>
          </div>
        </tab-pane>

        <tab-pane title="BSP">
          <span slot="title" class="text-uppercase">
            BSP
          </span>
          <div class="row">
            <div class="col-md-5">
              <div>
                <h2>
                  One Easy Settlement
                </h2>
                <div class="row mx-auto my-2 mt-4">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    BSP Reconciliation from HOT files and PDF file
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Single click posting as per BSP data</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">
                    BSP unadjustable account for easy management
                  </p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Easy ADM and ACM entry options</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Invoice Splitting</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">VAT Enabled</p>
                </div>
              </div>
            </div>
            <div class="col-md-7 order-lg-2 ml-lg-auto pb-5">
              <div class="position-relative pl-md-5 ">
                <img
                  src="img/screenshots/BSP.png"
                  class="img-center img-fluid feat-img-home"
                />
              </div>
            </div>
          </div>
        </tab-pane>

        <tab-pane title="Reports">
          <span slot="title" class="text-uppercase">
            Reports
          </span>
          <div class="row">
            <div class="col-md-5">
              <div>
                <h2>
                  Standardize Data; Aggregate Information
                </h2>
                <div class="row mx-auto my-2 mt-4">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Ageing reports</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Management reports</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Daily sales report</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Service wise reports</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Staff productivity reports</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Client and supplier reports</p>
                </div>
                <div class="row mx-auto my-2">
                  <icon
                    name="ni ni-key-25"
                    size="sm"
                    class="bg-gradient-white"
                    color="primary"
                    rounded
                  ></icon>
                  <p class="mx-2 my-1">Financial reports</p>
                </div>
              </div>
            </div>
            <div class="col-md-7 order-lg-2 ml-lg-auto pb-5">
              <div class="position-relative pl-md-5 ">
                <img
                  src="img/screenshots/Reports.png"
                  class="img-center img-fluid feat-img-home"
                />
              </div>
            </div>
          </div>
        </tab-pane>
      </card>
    </tabs>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-warning shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img src="img/ill/ill-2.svg" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-md-6 order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img src="" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-lg-8 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon
                  name="ni ni-diamond"
                  size="lg"
                  class="bg-gradient-white"
                  color="primary"
                  shadow
                  rounded
                ></icon>
              </div>
              <div class="py-2 px-4">
                <h4 class="display-3 text-white">Customers Tells Our Story</h4>
                <p class="text-white"></p>
              </div>
            </div>
            <carousel
              :per-page="1"
              :navigate-to="someLocalProperty"
              :mouse-drag="true"
              :autoplay="true"
              :loop="true"
              class="mt-5"
            >
              <slide
                data-index="0"
                data-name="MySlideName"
                @slideclick="handleSlideClick"
              >
                <card>
                  <div class="d-flex px-5">
                    <div class="pl-4">
                      <div class="col-sm-6 mx-auto">
                        <img
                          v-lazy="'img/logos/cli_fardan.jpeg'"
                          alt="Raised circle image"
                          class="img-fluid rounded-circle shadow-lg"
                          style="width: 200px;"
                        />
                      </div>
                      <div class="col-sm-6 col-12 mt-5 mt-sm-2 mx-auto">
                        <a href="#" class="text-success">Fardan, Director</a>
                      </div>
                      <div class="my-2 col-sm-6 mx-auto">
                        <img
                          src="img/logos/deira.png"
                          class="img-center img-fluid"
                        />
                      </div>

                      <p style="text-align:justify">
                        Travea as a product is an advanced solution which caters
                        to all our Operational requirements. As an Accounting
                        Software, many of its features and functionalities were
                        developed and customized as per our requirement by the
                        Travea team without any hesitation and delay. Their
                        dynamic support team is always available to meet any
                        type of urgent requirement or clarifications related to
                        the system. We had a seamless experience while
                        integrating Travea Web API with our front end system and
                        it ensures optimum accuracy on data sourcing and
                        distribution as the way we wanted it to be. Overall, we
                        are proud and pleased to be a Travia Associate which
                        enhance the accountability and efficiency of our support
                        team. We sincerely wish all the best to the entire team.
                      </p>
                    </div>
                  </div>
                </card>
              </slide>
              <slide>
                <card style="min-height:741px">
                  <div class="d-flex px-3">
                    <div class="pl-4">
                      <div class="col-sm-6 mx-auto">
                        <img
                          v-lazy="'img/logos/cli_irshad.jpeg'"
                          alt="Vinod"
                          class="img-fluid rounded-circle shadow-lg"
                          style="width: 200px;height:210px"
                        />
                      </div>

                      <div class="col-sm-6 col-12 mt-5 mt-sm-2 mx-auto">
                        <img class="ml-5" src="img/logos/trBay1.png" />
                      </div>
                      <div class="col-sm-6 col-12 my-5 mt-sm-2 mx-auto">
                        <a href="#" class="text-warning">
                          Irshad, Chief Accountant</a
                        >
                      </div>

                      <p style="text-align:justify">
                        First of all “Travea” is simply a user friendly system.
                        Being a user of Travea since last an year we could
                        enhance our work environment in many areas such as time
                        consumption, cost reduction in manpower, betterment of
                        management & financial reports and many. One can
                        recommend this with no fear as this is designed for
                        consolidating varieties of customer requirements &
                        modules Thanks a lot Travea team
                      </p>
                    </div>
                  </div>
                </card>
              </slide>
              <slide>
                <card style="min-height:741px">
                  <div class="d-flex px-3">
                    <div class="pl-4">
                      <div class="col-sm-6 mx-auto">
                        <img
                          v-lazy="'img/logos/cli_sonu.jpeg'"
                          alt="Vinod"
                          class="img-fluid rounded-circle shadow-lg"
                          style="width: 200px;"
                        />
                      </div>

                      <div class="col-sm-6 col-12 mt-5 mt-sm-2 mx-auto">
                        <img class="ml-3" src="img/logos/tripx.png" />
                      </div>
                      <div class="col-sm-6 col-12 my-5 mt-sm-2 mx-auto">
                        <a href="#" class="text-warning mx-4">
                          Sonu P, Accounts</a
                        >
                      </div>

                      <p style="text-align:justify">
                        I am glad to say that TRAVEA is very easy to use and
                        time saver software. I was used to other softwares
                        before, but now i surely recommend TRAVEA because of
                        more feature especially API integration ,settlement
                        modules etc.
                      </p>
                    </div>
                  </div>
                </card>
              </slide>

              <slide>
                <card style="min-height:741px">
                  <div class="d-flex px-3">
                    <div class="pl-4">
                      <div class="col-sm-6 mx-auto">
                        <img
                          v-lazy="'img/logos/avatar.jpg'"
                          alt="Vinod"
                          class="img-fluid rounded-circle shadow-lg"
                          style="width: 200px;"
                        />
                      </div>

                      <div class="col-sm-6 col-12 mt-5 mt-sm-2 mx-auto">
                        <img class="ml-3" src="img/logos/smart.png" />
                      </div>
                      <div class="col-sm-6 col-12 my-5 mt-sm-2 mx-auto">
                        <a href="#" class="text-warning mx-4">
                          Shehzad, Chief Accountant</a
                        >
                      </div>

                      <p style="text-align:justify">
                        Quick and Time Saver Software.
                        Visa and Ticket automation option is really helpful.
                      </p>
                    </div>
                  </div>
                </card>
              </slide>

              <slide
                data-index="0"
                data-name="MySlideName"
                @slideclick="handleSlideClick"
              >
                <card style="min-height:741px">
                  <div class="d-flex px-5">
                    <div class="pl-4">
                      <div class="col-sm-6 mx-auto">
                        <img
                          v-lazy="'img/logos/avatar.jpg'"
                          alt="Raised circle image"
                          class="img-fluid rounded-circle shadow-lg"
                          style="width: 200px;"
                        />
                      </div>

                      <div class="my-2 col-sm-6 mx-auto">
                        <img
                          src="img/logos/serenity.jpg"
                          class="img-center img-fluid"
                        />
                      </div>
                      <div class="col-sm-6 mx-auto my-3">
                        <a href="#" class="text-success mx-5"
                          >Vinod, Accountant</a
                        >
                      </div>

                      <p style="text-align:justify">
                        I would like to appreciate all the hard work and
                        diligent effort that your team have put on this
                        accounting software. Regarding the software we are using
                        this last two years and it has a wide variety of modules
                        and its simple to handle. The most important thing is
                        that we can access this software from anywhere. Of
                        course there is no doubt about the service of your team,
                        anytime available on what sup or on calls. On behalf of
                        Serenity Travel LLC I would like to thank you for your
                        services for last two years and we are expecting the
                        same thing will happen in coming years. We wish your
                        organization will grow higher and higher.
                      </p>
                    </div>
                  </div>
                </card>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  methods: {
    handleSlideClick(dataset) {
      console.log(dataset.index, dataset.name);
    },
  },

  components: {
    Tabs,
    TabPane,
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
.feat-img-home {
  border-radius: 2px;
  box-shadow: 1px 10px 10px 1px rgba(0, 0, 0, 0.5);
  transition: width 1s;
}
</style>
