<template>
  <div class="sidebar" >
    <div class="menu-items" >
        <router-link style="text-align:left"
        active-class="active"
        tag="button" exact
        class="side-btn ml-5"
        v-for="route in arrFeatureRoutes" :key="route.path" :to="route.path">
    {{route.name}}
  </router-link>   
    </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
        arrFeatureRoutes : [
          {path: "/invoicing",name:"Invoicing"},
          {path: "/tax",name:"VAT & GST"},        
          {path: "/multiCurrency",name:"Multi-Currency"},
           {path: "/repay",name:"Receivable & Payable"},        
          {path: "/inter_branch",name:"Inter-Branch"},       
          {path: "/reconcilliation",name:"Reconciliation"},       
          {path: "/settlement",name:"Settlement"},       
          {path: "/reports",name:"Reports"},       
          // {path: "/file_generation",name:"File Generation"},
          // {path: "/deals",name:"PLB & Special Deals"},       
          // {path: "/technology",name:"Integrations"},       
               
        ]
      };
  }
};
</script>

<style scoped>
.sidebar{
  background-color: #ffffff;
  border-right: 1px solid rgb(117, 114, 114,0.2);
}
.title {
  color: white;
  font-size: 24px;
  margin-top: 10px;
}
.menu-items {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 6px;
}
.menu-items > * {
  margin-top: -10px;
}
.side-btn {
  border: 0;
  padding: 16px 0px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: rgb(10, 10, 10);
  background-color: #ffffff;
}
.side-btn:focus {
  outline: none;
}
.side-btn.active {
  position: relative;
  background-color: white;
  color: rgb(77, 39, 245);
  font-weight: 600;
  /* margin-left: 10px; */
  /* border-radius: 30px 0 0 30px; */
}

.side-btn.active::before {
  top: -30px;
}
/* .side-btn.active::after {
  bottom: -30px;
} */

/* .side-btn.active::before,
.side-btn.active::after {
  position: absolute;
  content: "";
  right: 0;
  height: 30px;
  width: 30px;
  background-color: white;
} */
/* 
.side-btn.active .link-container::before {
  top: -60px;
} */

/* .side-btn.active .link-container::after {
  bottom: -60px;
  z-index: 99;
} */

/* .side-btn.active .link-container::before, .side-btn.active .link-container::after {
    position: absolute;
    content: "";
    right: 0px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
} */
</style>
