<template>
  <div>
    <div class="container shape-container d-flex">
      <div class="col px-0 my-5">
        <div class="row text-center">
          <div class="col-lg-12">
            <h1 class="display-3  text-dark">
              <span>Reconciliation</span>
            </h1>

            <div class="desc_head">
              Are you spending too much
              time on reconciling? Forget it, Travea can automate your reconciliation.
              
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-3 ml-lg-auto feat_cap my-auto">
              Put it <br />
              Together
            </div>
            <div class="col-md-9 order-lg-2 ml-lg-auto">
              <div class="position-relative pl-md-5">
                <img
                  src="img/screenshots/BSP.png"
                  class="img-center img-fluid feat-img-home feat-img-anime"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 data : () => ({
   longTextFeatReco : `While BSP or Billing and Settlement Plan helps
                      to run and simplify the interchange of data
                      and funds between travel agencies and airlines , Travea 
                      ensure that the transactions in a company’s financial 
                      records are consistent with independent third party reports.Bank,
                      credit & corporate cards, hotel and other suppliers,budget airlines are 
                      the other reconciliation features.`
 }),

};
</script>

