<template>
  <section
    class="section section-shaped section-lg my-0"
    style="min-height: 100vh"
  >
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
            
          >
          
            <template>
                <router-link class="tralogo mr-lg-4" to="/">
                <img src="img/logos/tra_logo.jpg">
            </router-link>
              <div class="text-center text-muted mb-4">SIGN UP TO TRAVEA</div>
              <form role="form">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Company"
                  v-model="strCompany"
                  addon-left-icon="ni ni-building"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-3"
                  v-model="strEmail"
                  type="email"
                  placeholder="Email"
                  addon-left-icon="ni ni-email-83"
                >
                </base-input>
                <!-- <base-input
                  alternative
                  class="mb-3"
                  v-model="strPassword"
                  type="password"
                  placeholder="Password"
                  addon-left-icon="ni ni-lock-circle-open"
                > -->
                <!-- </base-input> -->
                <base-input
                  alternative
                  class="mb-3"
                  v-model="strName"
                  placeholder="Name"
                  addon-left-icon="fa fa-user"                  
                >
                </base-input>
                 <base-input
                  alternative
                  class="mb-3"
                  v-model="strContact"
                  placeholder="Contact"
                  addon-left-icon="fa fa-phone"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                >
                </base-input>
          
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-map-marker"></i></span>                  
                  </div>
                  <select class="form-control" v-model="intCountryId">                  
                    <option value="" disabled selected hidden>Country</option>
                     <option v-for="users in ddlUser" v-bind:key="users.id" v-bind:value="{ id: users.id, text: users.name }">
                        {{users.name }}
                    </option>
                </select>
                </div>
                <base-checkbox class="mb-3" v-model="intChkPrivacy">
                  <small>
                    <span
                      >I agree with the
                      <a href="privacy" style="text-decoration: underline;">Privacy Policy</a> and <a href="terms" style="text-decoration: underline;">Terms of Service</a>
                    </span>
                  </small>
                </base-checkbox>
          
                <div style="border-left: 6px solid red;border-radius: 50px 20px;" class="row my-3" v-if="arrErrors.length">
                    <p class="mx-auto my-3 text-danger" >
                    <ul>
                        <li v-for="error in arrErrors" v-bind:key="error">{{ error }}</li>
                    </ul>
                    </p>              
                </div>

                <div v-if="loading" class="row mt-3 mx-auto py-2 px-auto" style="text-align:center;display: flex;
  justify-content: center;">
                  <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    <!-- here put a spinner or whatever you want to indicate that a request is in progress -->                </div>
                <div v-else>
                    <!-- request finished -->
                </div>
                      
                <div style="border-left: 20px solid #32CD32;background-color: #F5F5F5;border-radius: 50px 20px;" class="row mt-3 mx-auto py-2" v-if="strSuccessMessage">
                    <p class="text-success ml-3 my-auto" >
                    <b>{{strSuccessMessage}}</b>       
                    </p>                            
                </div>
                <div class="row mt-3 mx-auto py-2" v-if="strSuccessMessage && intActiveTrialMode == 1">
                    <p class="text-primary my-auto" >
                    <b>You will receive a confirmation E-mail.</b> <span style="text-decoration: underline;font-style:italic"><a href="http://35.200.200.227:8585/">Login here</a></span>                        
                    </p>    
                   
                </div>
                <div style="border-left: 20px solid red;background-color: #F5F5F5;border-radius: 50px 20px;" class="row mt-3 mx-auto py-2" v-if="strErrorMessage">
                    <p class="text-danger mx-auto my-auto" >
                    <b>{{strErrorMessage}}</b>       
                    </p>   
                </div>
                           
                <div class="text-center">
                  <base-button type="primary" class="my-3"  @click="fnCreateUser()" :disabled="btndisable"
                    >Create account</base-button
                  >
                </div>
                <div>
                
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from '@/axios'
import lst_countries from '../assets/countries.js'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  data : () => ({
      strCompany: "",
      strEmail: "",
      // strPassword: "",
      strName: "",
      intCountryId: "",
      strContact: "",
      arrErrors: [],
      strSuccessMessage : "",
      strErrorMessage : "",
      intChkPrivacy : false,
      intActiveTrialMode : 0,
      ddlUser: lst_countries,
      User: -1,
      Userdrp: "Country",
      intBtnDisableFlag : 0,
      loading : false,
      color: '#cc181e',
      color1: '#5bc0de',
      size: '15px',
      radius: '2px'
  }),
  components : {
    PulseLoader
  },

  computed: {
        btndisable: function() {
            return (this.intBtnDisableFlag == 1)
        }
  },

  methods: {
    
    fnCreateUser () {
            this.arrErrors = [];
            this.strSuccessMessage = "";
            this.strErrorMessage = "";
            let targetUrl = "";
            if (!this.strCompany) {
              this.arrErrors.push("Company required.");
            }
            if (!this.strEmail) {
              this.arrErrors.push("Email required.");
            } else if (!this.validEmail(this.strEmail)) {
              this.arrErrors.push('Valid email required.');
            }
            // if (!this.strPassword) {
            //   this.arrErrors.push("Password required.");
            // }
            if (this.strName == false) {
              this.arrErrors.push("Name is required.");
            }
            if (!this.intCountryId) {
              this.arrErrors.push("Country required.");
            }
            if (!this.strContact) {
              this.arrErrors.push("Contact required.");
            }
            if (this.intChkPrivacy == false) {
              this.arrErrors.push("Please accept the Terms of Service and Privacy Policy");
            }
            let payload = {                        
                    'STR_USER_NAME' : this.strEmail,
                    'STR_USER_GROUP' : 'SUBSCRIPTION',
                    'STR_USER_STATUS' : 'ACTIVE',           
                    'STR_BRANCH_CODE' : this.strCompany,
                    'STR_BRANCH_NAME' : this.strCompany,
                    // 'STR_PASSWORD' : this.strPassword,
                    'STR_ACTUAL_NAME' : this.strEmail,
                    'STR_PHONE_NUMBER' : this.strContact,
                    'STR_EMAIL' : this.strEmail,
                    'STR_NAME' : this.strName,
                    'STR_DEFAULT_BRANCH' : this.strCompany,
                    'STR_DEFAULT_DEPARTMENT' : 'GA',
                    'INT_COUNTRY_ID' : this.intCountryId.id,
                    'STR_COUNTRY_NAME' : this.intCountryId.text,
                    'STR_MAIL_TYPE' : 'REGISTER'
                    };

            if (this.intActiveTrialMode == 1) {            
                if (this.arrErrors.length>0) {
                    this.intBtnDisableFlag = 1
                    this.loading = false
                    return false
                }
                else 
                {
                  this.loading = true
                    targetUrl = "masters/wsAutoCreateBranch/";                    
                    let dctAuthenticationData = { 
                      'STR_USER':'WS',
                      'STR_PASSWORD':'123',
                      'STR_PRODUCT':'TRAVEL_AC'
                    };

                    let jsnRegistrationData = {
                      'JSN_AUTHENTICATION_DATA' : dctAuthenticationData,
                      'JSN_MASTER_DATA': payload
                    };
                    
                    let dctParams = {"dctParameters": jsnRegistrationData} 
                    axios
                      .post(targetUrl, dctParams)
                      .then((response) => {
                        if (response.data.strStatus == "SUCCESS") {
                          this.strSuccessMessage = response.data.strMessage
                          this.intBtnDisableFlag = 0
                        }
                        else{
                          this.strErrorMessage = response.data.strMessage
                          this.intBtnDisableFlag = 1
                          this.loading = false

                        }
                      })
                      .catch((error) => {
                        this.intBtnDisableFlag = 1
                        console.log(error)
                      }); 
                  }
            }
            else
            {
              this.loading = true
              this.intBtnDisableFlag = 1
              let targetMailUrl = "masters/wsAutoSendMail/";             
              let jsnMailData = {
                'JSN_MASTER_DATA': payload
              };
              let dctParams = {"dctParameters": jsnMailData} 
              axios
                .post(targetMailUrl, dctParams)
                .then((response) => {
                  if (response.data.strStatus == "SUCCESS") {
                    this.strSuccessMessage = 'You have been registered with our portal.You will receive an email soon.'
                    this.intBtnDisableFlag = 1
                  }
                  else{
                    this.strErrorMessage = 'Please try again !'
                    this.intBtnDisableFlag = 0
                    
                  }
                  this.loading = false
                })
                .catch((error) => {
                  console.log(error)
                  this.intBtnDisableFlag = 0
                  this.loading = false

              });        
            }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    //   onSelect({name, iso2, dialCode}) {
    //    console.log(name, iso2, dialCode);
    //  },
  },
};
</script>

<style lang="scss" scoped>
.tralogo { 
      
    img{
        border-radius: 50%;
        height: 85px;
        border: 1px solid #ddd;
        padding: 5px;
        display: block;
        margin-left: auto;
        margin-right: auto;
         box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    }   
    img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
    .iconspan {
        float: left;
        margin-left: 6px;
        margin-top: -20px;
        position: relative;
        z-index: 2;
        color: red;
    }
}


</style>
