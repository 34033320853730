<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0" style="min-height:40vh">
        <div class="shape shape-style-1 shape-primary">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            
          </div>
        </div>
        

        <div class="dashboard">
          <Sidebar />
          <div class="content">
            <router-view />
            
          </div>
        </div>
        
        
      </section>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/FeaturesSidebar";
export default {
  name: "Features",
  components: {
    Sidebar,
  },
  
};
</script>

<style>
.dashboard {
  display: grid;
  grid-template-columns: 1fr 5fr;
  background-color: rgb(239, 240, 248);
  height: 100%;
  width: 100%;
}

.content {
  background-color: rgb(255, 255, 255);
  border-radius: 0px;
  margin: 0px 0px 0px 0px;
}
</style>
