import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
// import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Pricing from '@/views/Pricing.vue';
import Contact from '@/views/Contact.vue';
import LoginRedirect from '@/views/LoginRedirect.vue';
import AdminLogIn from '@/views/AdminViews/AdminLogIn.vue';
import AdminSettings from '@/views/AdminViews/AdminSettings.vue';
import AdminDashboard from '@/views/AdminViews/Dashboard.vue';
import Invoicing from '@/views/FeaturesViews/Invoicing.vue';
import Tax from '@/views/FeaturesViews/Tax.vue';
import Reconcilliation from '@/views/FeaturesViews/Reconcilliation.vue';
import MultiCurrency from '@/views/FeaturesViews/MultiCurrency.vue';
import InterBranch from '@/views/FeaturesViews/InterBranch.vue';
import Deals from '@/views/FeaturesViews/Deals.vue';
import FileGeneration from '@/views/FeaturesViews/FileGeneration.vue';
import Settlement from '@/views/FeaturesViews/Settlement.vue';
import Technology from '@/views/FeaturesViews/Technology.vue';
import Featcover from '@/views/FeaturesViews/Featcover.vue';
import Repay from '@/views/FeaturesViews/Repay.vue';
import Reports from '@/views/FeaturesViews/Reports.vue';
import Privacy from '@/views/PoliciesViews/Privacy.vue';
import Terms from '@/views/PoliciesViews/Terms.vue';
import Features from '@/views/Features.vue';
import AppHeader from "@/layouts/AppHeader";
import AppFooter from "@/layouts/AppFooter";
import AdminHeader from "@/layouts/AdminLayouts/AdminHeader";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   components: {
  //     header: AppHeader,
  //     default: Login,
  //   }
  // },
  {
    path: '/register',
    name: 'Register',
    components: {
      header: AppHeader,
      default: Register,
    }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    components: {
      header: AppHeader,
      default: Pricing,
      footer: AppFooter
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: {
      header: AppHeader,
      default: Contact,
      footer: AppFooter
    }
  },
   {
     path: '/login_redirect',
     name: 'LoginRedirect',
     components: {
       default: LoginRedirect,
     }
   },
  {
    path: '/admin',
    name: 'Admin',
    components: {
      default: AdminLogIn,
    }
  },
  {
    path: '/admin_settings',
    name: 'AdminSettings',
    components: {
      header: AdminHeader,
      default: AdminSettings,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      header: AdminHeader,
      default: AdminDashboard,
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    components: {
      default: Privacy,
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    components: {
      default: Terms,
    }
  },
  {
    path: '/features',
    name: 'Featcover',
    components: {
      header: AppHeader,
      default: Features,
      footer: AppFooter
    },
    children: [
      {
        path: '/features',
        name: 'Featcover',
        component: Featcover,
      },
      {
        path: '/invoicing',
        name: 'invoicing',
        component: Invoicing,
      },
      {
        path: '/tax',
        name: 'tax',
        component: Tax,
      },
      {
        path: '/reconcilliation',
        name: 'reconcilliation',
        component: Reconcilliation,

      },
      {
        path: '/multiCurrency',
        name: 'multiCurrency',
        component: MultiCurrency,
      },
      {
        path: '/inter_branch',
        name: 'InterBranch',
        component: InterBranch,
      },
      {
        path: '/deals',
        name: 'Deals',
        component: Deals,
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports,
      },
      {
        path: '/file_generation',
        name: 'FileGeneration',
        component: FileGeneration,
      },
      {
        path: '/technology',
        name: 'Technology',
        component: Technology,
      },
      {
        path: '/settlement',
        name: 'Settlement',
        component: Settlement,
      },
      {
        path: '/repay',
        name: 'Repay',
        component: Repay,
      },
    ]
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router