<template>
  <header class="header-global">
    <base-nav type="gradient-success" effect="light" expand>
      <!-- <div style="background-color:white" > -->
        <router-link slot="brand" class="navbar-brand mr-lg-5" to="/dashboard">
        <img src="img/logos/orion-travea.png" />
      </router-link>
      <!-- </div> -->
     
      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="../index.html">
            <img src="img/brand/blue.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item dropdown">
          <a
            href=""
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">UPDATE</span>
          </a>

          <ul class="dropdown-menu multi-column dropdown-menu-lg">
            <div class="row">
              <div class="col-sm-12">
                <ul class="multi-column-dropdown">
                  <a href="" class="media d-flex align-items-center">
                    <div class="media-body">
                      <h6 class="text-dark mb-md-1">Pricing</h6>
                    </div>
                  </a>
                  <a href="" class="media d-flex align-items-center">
                    <div class="media-body">
                      <h6 class="text-dark mb-md-1">Pricing Features</h6>
                    </div>
                  </a>
                  <a href="" class="media d-flex align-items-center">
                    <div class="media-body">
                      <h6 class="text-dark mb-md-1">Deals</h6>
                    </div>
                  </a>

                  <a href="" class="media d-flex align-items-center">
                    <div class="media-body">
                      <h6 class="text-dark mb-md-1">Contact</h6>
                    </div>
                  </a>
                </ul>
              </div>
            </div>
          </ul>
        </li>

        <li>
             <a
            href=""
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">ADD</span>
          </a>
        </li>

         <li class="nav-item">
          <a
            href="admin_settings"
            class="nav-link"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">SETTINGS</span>
          </a>         
        </li>

      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item d-none d-lg-block ml-lg-4">
          <a href="">
            <span>
              <i class="fa fa-sign-out mr-2" style="font-size: 1.5em;"></i>
            </span>
            <!-- <span class="nav-link-inner--text">LOG OUT</span> -->
          </a>
        </li>

       
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
// import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
export default {
  components: {
    BaseNav,
    CloseButton,
    // BaseDropdown
  },
};
</script>
<style></style>
