<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0" style="min-height:40vh">
        <div class="shape shape-style-1 shape-primary">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-3  text-white">
                  <span>Pricing plans most refined for business</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section section-lg pt-lg-5 mt--100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row row-grid">
                <div class="col-lg-3">
                  <card
                    class="border-0 price-card"
                    hover
                    shadow
                    body-classes="py-5"
                    style="min-height:1050px"
                  >
                    <h6 class="text-dark text-uppercase">Standard</h6>
                    <!-- <p class="description mt-3">Orders and invoicing</p> -->
                    <!-- <h1 class="text-warning text-uppercase">&#36;59.99</h1> -->
                    <div class="row">
                      <base-button
                        tag="a"
                        href="register"
                        type="success"
                        class="mt-4 mx-1 p-2"
                        outline
                        textColor="dark"
                      >
                        Buy Now
                      </base-button>
                      <base-button
                        tag="a"
                        href="register"
                        type="warning"
                        class="mt-4 mx-1 p-2"
                        outline
                        textColor="dark"
                      >
                        Try Free
                      </base-button>
                    </div>
                    <div class="row my-4">
                      <ul style="list-style-type: none;margin-left:-35px">
                        <li
                          class="mt-2"
                          v-for="feat1 in arrStandardFeat"
                          :key="feat1"
                        >
                          <i style="color:green" class="fa fa-check"></i>
                          {{ feat1 }}
                        </li>
                      </ul>
                    </div>
                  </card>
                </div>
                <div class="col-lg-3">
                  <card
                    class="border-0 price-card"
                    hover
                    shadow
                    body-classes="py-5"
                    style="min-height:1050px"
                  >
                    <h6 class="text-dark text-uppercase">Professional</h6>
                    <!-- <p class="description mt-3">Business GST compliant</p> -->
                    <!-- <h1 class="text-warning text-uppercase">&#36;79.99</h1> -->
                    <div class="row">
                      <base-button
                        tag="a"
                        href="register"
                        type="success"
                        class="mt-4 mx-1 p-2"
                        outline
                        textColor="dark"
                      >
                        Buy Now
                      </base-button>
                      <base-button
                        tag="a"
                        href="register"
                        type="warning"
                        class="mt-4 mx-1 p-2"
                        outline
                        textColor="dark"
                      >
                        Try Free
                      </base-button>
                    </div>
                    <div class="row my-4">
                      <ul style="list-style-type: none;margin-left:-35px">
                        <p style="color:green" class="description mt-2">
                          Includes everything in Standard +
                        </p>
                        <li
                          class="mt-2"
                          v-for="feat2 in arrProFeat"
                          :key="feat2"
                        >
                          <i style="color:green" class="fa fa-check"></i>
                          {{ feat2 }}
                        </li>
                      </ul>
                    </div>
                  </card>
                </div>
                <div class="col-lg-3">
                  <card
                    class="border-0 price-card"
                    hover
                    shadow
                    body-classes="py-5"
                    style="min-height:1050px"
                  >
                    <h6 class="text-dark text-uppercase">Premium</h6>
                    <!-- <p class="description mt-3">Business you are.</p> -->
                    <!-- <h1 class="text-warning text-uppercase">&#36;99.99</h1> -->
                    <div class="row">
                      <base-button
                        tag="a"
                        href="register"
                        type="success"
                        class="mt-4 mx-1 p-2"
                        outline
                        textColor="dark"
                      >
                        Buy Now
                      </base-button>
                      <base-button
                        tag="a"
                        href="register"
                        type="warning"
                        class="mt-4 mx-1 p-2"
                        outline
                        textColor="dark"
                      >
                        Try Free
                      </base-button>
                    </div>
                    <div class="row my-4">
                      <ul style="list-style-type: none;margin-left:-35px">
                        <p style="color:green" class="description mt-2">
                          Includes everything in Professional +
                        </p>
                        <li
                          class="mt-2"
                          v-for="feat3 in arrPremiumFeat"
                          :key="feat3"
                        >
                          <i style="color:green" class="fa fa-check"></i>
                          {{ feat3 }}
                        </li>
                      </ul>
                    </div>
                  </card>
                </div>
                <div class="col-lg-3">
                  <card
                    class="border-0 price-card"
                    hover
                    shadow
                    body-classes="py-5"
                    style="min-height:1050px"
                  >
                    <h6 class="mb-4 text-dark text-uppercase">Enterprise</h6>
                    <!-- <p class="description mt-3">Aspect of your business</p> -->
                    <!-- <h4 class="mb-3 text-warning text-center">
                      Contact Sales
                    </h4> -->
                    <div class="row">
                      <base-button
                        tag="a"
                        href="register"
                        type="success"
                        class="mt-4 mx-1 p-2 col-md-12"
                        outline
                        textColor="dark"
                      >
                        Request Demo
                      </base-button>
                    </div>
                    <div class="row my-4">
                      <ul style="list-style-type: none;margin-left:-35px">
                        <p style="color:green" class="description mt-2">
                          Includes everything in Premium +
                        </p>
                        <li
                          class="mt-2"
                          v-for="feat4 in arrEliteFeat"
                          :key="feat4"
                        >
                          <i style="color:green" class="fa fa-check"></i>
                          {{ feat4 }}
                        </li>
                      </ul>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <h1 class="text-dark text-center my-5 ">Comparison Chart</h1>

      <table class="table table-bordered pricing-table">
        <tr>
          <th style="width:50%">Features</th>
          <th>Standard</th>
          <th>Professional</th>
          <th>Premium</th>
          <th>Enterprise</th>
        </tr>

        <tr v-for="items in arrFeaturesTableItems" :key="items.name">
          <td>{{ items.name }}</td>
          <td v-for="check in items.check" :key="check">
            <i v-if="check == 1" style="color:green" class="fa fa-check"></i>
            <i v-else style="color:red" class="fa fa-remove"></i>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arrFeaturesTableItems: [
        { name: "Proforma Invoice", check: [1, 1, 1, 1] },
        { name: "Sale", check: [1, 1, 1, 1] },
        { name: "Receipts", check: [1, 1, 1, 1] },
        { name: "Payments", check: [1, 1, 1, 1] },
        { name: "Journals", check: [1, 1, 1, 1] },
        { name: "VAT", check: [1, 1, 1, 1] },
        { name: "Matching/ allocation", check: [1, 1, 1, 1] },
        { name: "Reconciliation", check: [1, 1, 1, 1] },
        { name: "Number of users 2", check: [1, 1, 1, 1] },
        { name: "Daily sales report", check: [1, 1, 1, 1] },
        { name: "Outstanding reports", check: [1, 1, 1, 1] },
        { name: "Client and supplier reports", check: [1, 1, 1, 1] },
        { name: "Service wise report", check: [1, 1, 1, 1] },
        { name: "Staff productivity report", check: [1, 1, 1, 1] },
        { name: "Queries and searches", check: [1, 1, 1, 1] },
        { name: "Document number", check: [1, 1, 1, 1] },
        { name: "Ticket/voucher", check: [1, 1, 1, 1] },
        { name: "Pax name", check: [1, 1, 1, 1] },
        { name: "Service", check: [1, 1, 1, 1] },
        { name: "Client/ supplier", check: [1, 1, 1, 1] },
        { name: "Document amount Range", check: [1, 1, 1, 1] },
        { name: "Attachments", check: [0, 1, 1, 1] },
        { name: "Front office issue", check: [0, 1, 1, 1] },
        { name: "Not reported sales", check: [0, 1, 1, 1] },
        { name: "Chart of accounts additions", check: [0, 1, 1, 1] },
        { name: "Credit control", check: [0, 1, 1, 1] },
        { name: "Locking Data", check: [0, 1, 1, 1] },
      ],
      arrStandardFeat: [
        "Number of users 2",
        "Proforma Invoice",
        "Sale",
        "Receipts",
        "Payments",
        "Journals",
        "VAT",
        "Matching/ Allocation",
        "Reconciliation",
        "Daily sales report",
        "Outstanding reports",
        "Client and supplier reports",
        "Service wise report",
        "Staff productivity report",
        "Queries and Searches",
        "Document number ",
        "Ticket/ Voucher",
        "Service",
        "Client/ Supplier",
        "Document amount Range",
      ],
      arrProFeat: [
        "Number of users 5",
        "Attachments",
        "Front office  issue",
        "Not reported sales",
        "Chart of accounts additions",
        "Credit control",
        "Locking Data",
      ],
      arrPremiumFeat: ["Number of users 20",
        "Customer sharing", 
        "Supplier Sharing", 
        "Multiple Debit Credit note", 
        "Discount / Extracollection",
        "Batch sale",
        "Auto invoice grouping",
        "Auto JV Posting",
        "Payroll",
        "Package Sale",
        "Settlement",
        "Graphical Dashboards",
        ],
      arrEliteFeat: [],
    };
  },
};
</script>
