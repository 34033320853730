<template>
    <div>

        <section class="section-shaped  my-0">
            <div class="shape shape-style-1 shape-primary shape-skew">
               
            </div>
            <div class="container text-center justify-content-center pt-lg pb-300">
                <div class="row my-3">
                    <div class="col-lg-10">
                        <h2 class="display-3 text-white">Contact Us</h2>
                        <p class="lead text-white">How can we help ? Talk to a member of our team</p>
                    </div>
                </div>
               
            </div>
        </section>
        <section class="section section-sm pt-lg-1 section-contact-us">
            <div class="container">
                <div class="row text-center justify-content-center mt--300">
                    <div class="col-lg-6">
                        <card gradient="white" body-classes="p-lg-4" style="border-bottom: 1mm ridge rgb(255, 100, 0, .6);">
                            <i
                                class="fa fa-phone"
                                color="dark" 
                                style="font-size: 2em;"                               
                            ></i>
                            <h4 class="my-3">Talk to Sales</h4>
                            <p>
                                Interested in Travea? Chat with our sales team.
                            </p>
                            <p class="mt-4" style="font-size:1.2em">                                
                               +91 9349441010
                            </p>
                        </card>
                        </div>
                         <div class="col-lg-6">
                        <card gradient="white" shadow body-classes="p-lg-4" style="border-bottom: 1mm ridge rgb(170, 50, 220, .6);">
                             <i
                                class="fa fa-comments"
                                color="dark" 
                                style="font-size: 2em;"                               
                            ></i>
                            <h4 class="my-3">Contact Customer Support</h4>
                             <p>
                                User of Travea? Chat with our Technical team.
                            </p>
                            <p class="mt-4" style="font-size:1.2em">
                               +91 9349121212
                            </p>
                        </card>
                        
                        
                    </div>
                </div>
                
            </div>
        </section>
         <section class="section section-sm pt-lg-6">
            <div class="container">
                <div class="row justify-content-center mt--600">
                    <div class="col-lg-12">
                        <div gradient="secondary" shadow body-classes="p-lg-4">
                            <h4 class="mb-1">Have a query?</h4>
                            <p class="mt-0">Your project is very important to us.</p>
                            <base-input class="mt-5"
                                        alternative
                                        placeholder="Your name"
                                        v-model="strEnquiryName"
                                        addon-left-icon="ni ni-user-run">
                            </base-input>
                            <base-input alternative
                                        placeholder="Email address"
                                        v-model="strEnquiryMail"
                                        addon-left-icon="ni ni-email-83">
                            </base-input>
                            <base-input class="mb-4">
                                    <textarea class="form-control form-control-alternative" name="name" rows="4"
                                              cols="80" placeholder="Type a message..." v-model="strEnquiryMessage"></textarea>
                            </base-input>
                              <div style="border-left: 6px solid red;border-radius: 50px 20px;" class="row my-3" v-if="arrErrors.length">
                    <p class="mx-auto my-3 text-danger" >
                    <ul>
                        <li v-for="error in arrErrors" v-bind:key="error">{{ error }}</li>
                    </ul>
                    </p>              
                </div>
                             <div v-if="loading" class="row mt-3 mx-auto py-2 px-auto" style="text-align:center;display: flex;
  justify-content: center;">
                  <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    <!-- here put a spinner or whatever you want to indicate that a request is in progress -->                </div>
                <div v-else>
                    <!-- request finished -->
                </div>
                  <div style="border-left: 20px solid #32CD32;background-color: #F5F5F5;border-radius: 50px 20px;" class="row mt-3 mx-auto py-2" v-if="strSuccessMessage">
                    <p class="text-success ml-3 my-auto" >
                    <b>{{strSuccessMessage}}</b>       
                    </p>                            
                </div>
                  <div style="border-left: 20px solid red;background-color: #F5F5F5;border-radius: 50px 20px;" class="row mt-3 mx-auto py-2" v-if="strErrorMessage">
                    <p class="text-danger mx-auto my-auto" >
                    <b>{{strErrorMessage}}</b>       
                    </p>   
                </div>
                            <base-button type="dark" round block size="lg" @click="fnSendEnquiry()">
                                Send Message
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-2 bg-gradient-warning shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container pt-lg pb-300">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-10">
                        <h2 class="display-3 text-white">Locate Us</h2>
                        <p class="lead text-white"></p>
                            
                    </div>
                </div>
                <section class="section section-sm pt-lg-4 section-contact-us">
            <div class="container">
                <div class="row text-center justify-content-center mt--600">
                    <div class="col-lg-12">
                        <card body-classes="p-lg-4" style="border-bottom: 1mm ridge rgb(255, 100, 0, .6);">
                            <h4 class="mb-4" >INDIA</h4>
                             <i
                                class="fa fa-map-marker"
                                color="dark" 
                                style="font-size: 3em;"                               
                            ></i>
                            <p class="mt-4">
                                FFE, Express Tower <br>
                                Calicut,Kerala <br>
                                +91 9349441010 <br>
                               ceo@orioninfo.in,hr@orioninfo.in
                            </p>
                        </card>
                        
                        
                        
                    </div>
                </div>
                
            </div>
        </section>
               
            </div>
        </section>
    </div>
</template>

<script>
import axios from '@/axios'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    data : () => ({ 
      strEnquiryMail: "",
      strEnquiryName : "",
      strEnquiryMessage: "",

      arrErrors : [],
      strSuccessMessage : "",
      strErrorMessage : "",

      intBtnDisableFlag : 0,
      loading : false,
      color: '#cc181e',
      color1: '#5bc0de',
      size: '15px',
      radius: '2px'
    }),
    components : {
        PulseLoader
    },
    computed: {
        btndisable: function() {
            return (this.intBtnDisableFlag == 1)
        }
    },
    methods: {
        fnSendEnquiry () {
            this.arrErrors = [];
            this.strSuccessMessage = "";
            this.strErrorMessage = "";
            if (this.strEnquiryName == false) {
              this.arrErrors.push("Name is required.");
            }
            if (!this.strEnquiryMail) {
              this.arrErrors.push("Email required.");
            } else if (!this.validEmail(this.strEnquiryMail)) {
              this.arrErrors.push('Valid email required.');
            }         
             if (!this.strEnquiryMessage) {
              this.arrErrors.push("Message required.");
            }

            let payload = {                        
                'STR_USER_NAME' : this.strEnquiryName,
                'STR_EMAIL' : this.strEnquiryName,
                'STR_MESSAGE' : this.strEnquiryMessage,          
                'STR_MAIL_TYPE' : "QUERY",          
                
                };
            if (this.arrErrors.length>0) {
                this.intBtnDisableFlag = 1
                this.loading = false
                return false
            }
            else 
            {
                
            this.loading = true
            this.intBtnDisableFlag = 1
            let targetUrl = "masters/wsAutoSendMail/";             
            let jsnMailData = {
                'JSN_MASTER_DATA': payload
            };
            let dctParams = {"dctParameters": jsnMailData} 
            axios
                .post(targetUrl, dctParams)
                .then((response) => {
                if (response.data.strStatus == "SUCCESS") {
                    this.strSuccessMessage = 'Query sent.Our team will contact you soon.'
                    this.intBtnDisableFlag = 1
                }
                else{
                    this.strErrorMessage = 'Please try again !'
                    this.intBtnDisableFlag = 0
                }
                this.loading = false
                })
                .catch((error) => {
                console.log(error)
                this.intBtnDisableFlag = 0
                this.loading = false
                });       
        
            }
        },
        validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
        },
}
}
</script>
    
        