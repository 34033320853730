<template>
  <div>
    <section>
      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div>
      <!--End tables-->
    </section>
  </div>
</template>
<script>
import PageVisitsTable from "./AdminDash/PageVisitsTable";
import SocialTrafficTable from "./AdminDash/SocialTrafficTable";

export default {
  components: {
    PageVisitsTable,
    SocialTrafficTable,
  },
};
</script>
